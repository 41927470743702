// Chat message validation
export const validateInput = (data) => {
    // // Don't delete this code
    // if(data.length === 0) return true;
    // const regex = /^[a-zA-Z0-9\s\-,.!?()/@#&£€$=+*"']+$/;
    // return regex.test(data);
    return true;
};

// Validate onChange Input fields in Form
export const validateFormInput = (type:string, data:any) => {
  if((typeof data === 'string' && data.length === 0) || (typeof data === 'number' && data === 0)) return true;
  const regex = {
    'select': /^[a-zA-Z0-9\s\-_,.!?()/@"']+$/,
    'text': /^[a-zA-Z0-9\s\-_,.!?()/@"']+$/,
    'email': /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.+-]+\.[a-zA-Z0-9]{2,}$/,
    'number': /^(?:1000000(?:\.0{1,2})?|[0-9]{1,6}(?:\.\d{1,2})?)$/,
  };
  return regex[type].test(data);
};

// Validate form
export const validateForm = (form, values) => {
  if(form){
    let tmpValues:object = form.reduce((acc: any, item: any) => {
      if (item.action !== "submit_form") {
        acc[item.action] = (values[item.action] !== "/" && values[item.action]) || null;
      }
      return acc;
    }, {});
    return !Object.values(tmpValues).some(value => value === null);
  }
};