import React, { useContext, useState, useEffect, useRef } from "react";
import { Socket } from 'socket.io-client';
import GlobalContext from "../../../../context/GlobalContext";
import { validateInput } from "../utils/validate";
import loadingIcon from '../../../../assets/image/gif/loader-transparent.gif';
import icoUpload from '../../../../assets/image/rebrand/svg/youfibre-upload.svg';
import icoClose from '../../../../assets/image/rebrand/svg/close.svg';
import { connectToWebsocket, didAgentPost, sendMessage } from "../services/chatService";
import InputForm from "./styled/InputForm";

const ChatInput = ({open}) => {
    const gContext: any = useContext(GlobalContext);
    const [chatSending, setChatSending] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('');
    const sc = useRef<Socket | null>(null);

    useEffect(() => { 
        if(message.length && gContext.twilioChat.chatCaseId){ 
            if(!sc.current) sc.current = connectToWebsocket(localStorage.getItem('chatSessionId'));
            sc.current && sc.current?.emit(`web-chat-input`, {
                caseId: gContext.twilioChat.chatCaseId,
                conversationId: gContext.twilioChat.chatConversationId,
                message: message,
                sender: 'CUSTOMER'
            });
            gContext.goSetTwilioChat({...gContext.twilioChat, lastTimeActive: Date.now()});
        }
    }, [message]);
  
    useEffect(() => {
      if(gContext.twilioChat.chatCaseId){
        sc.current && gContext.twilioChat.chatCaseId && sc.current.emit("joinRoom", gContext.twilioChat.chatCaseId);  
      }
    }, [gContext.twilioChat.chatCaseId]);
  
    useEffect(() => {
      !chatSending && document.getElementById('inputMessage')?.focus();
    }, [chatSending]);

    const submitMessage = async (e, msg = "") => {
        e.preventDefault();
        try {
            setMessage('');
            setChatSending(true);
            const data = await sendMessage(msg, gContext.twilioChat.uploadedFiles, 'CUSTOMER');
            if(data){
                gContext.goSetTwilioChat({...gContext.twilioChat, 
                    uploadedFiles: [],
                    logged: true,
                    chatLoading: false,
                    lastTimeActive: didAgentPost(gContext.twilioChat.messages) ? Date.now() : null,
                });
                setChatSending(false);
            } else {
                setChatSending(false);
            }
        } catch (error) {
            setChatSending(false);
        }
    };

    // Upload files - Remove file from list
    const removeFile = (name:string) => {
      const tmpFiles = gContext.twilioChat.uploadedFiles.filter((itm:any) => itm.name !== name);
      gContext.goSetTwilioChat({...gContext.twilioChat, uploadedFiles: tmpFiles});
    };

    return (
        <>
        {gContext.twilioChat.chatConversationId && 
          <InputForm>
            <div className="wrapper">
              <input id="inputMessage" className={!validateInput(message) ? 'invalid' : ''} disabled={chatSending} autoComplete='off' maxLength={500} onChange={(e) => setMessage(e.target.value)} value={message} type="text" placeholder="Write a reply..." />
              { chatSending && <img src={loadingIcon} width={10} height={10} /> }
              <img className='action' alt='Add attachment' src={icoUpload} onClick={() => open()} />
              <button type='submit' onClick={(e) => submitMessage(e, message)} disabled={!validateInput(message) || chatSending}>Send</button>
            </div>
          </InputForm>}        
        {gContext.twilioChat.uploadedFiles && Object.keys(gContext.twilioChat.uploadedFiles).length > 0 && <div className='files'>
          {gContext.twilioChat.uploadedFiles.map((file:any) => (
            <div className='file' key={file.name}>
              <div className='fileName'>{file.name}</div>
              <img className='action' src={icoClose} onClick={() => removeFile(file.name)} />
            </div>
          ))}
        </div>}
        </>
    );
};

export default ChatInput;