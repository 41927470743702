import React, { useContext, useState, useEffect, useRef } from "react";
import { useDropzone } from 'react-dropzone';
import { toastrMsg } from '../../../utils/alert';
import GlobalContext from "../../../context/GlobalContext";
import ChatHeader from "./components/ChatHeader";
import MessageList from "./components/MessageList";
import ChatInput from "./components/ChatInput";
import { closeConversation, didAgentPost, scrollToBottom } from "./services/chatService";
import loadingIcon from '../../../assets/image/gif/loader-transparent.gif';
import notificationSound from "../../../assets/audio/notification.wav";
import ChatContent from "./components/styled/ChatContent";
import { initialTwilioChatState } from "./utils/chats";
import { Lightbox } from "react-modal-image";
import moment from "moment";

const Chat = ({...props}) => {
    const gContext: any = useContext(GlobalContext);
  
    let timer = useRef<number>(0);
    let [countdownInterval, setCountdownInterval] = useState<any>(null);

    const titleRef = useRef<string>("");
    const intervalRef = useRef<any | null>(null);
    const [animateTabTitle, setAnimateTabTitle] = useState<boolean>(false);
    const [titleIndex, setTitleIndex] = useState(-1);

    useEffect(() => {
      if(gContext.twilioChat.chatUnreaded) {
        let sound = new Audio(notificationSound);
        sound.setAttribute('crossorigin', 'anonymous'); 
        sound.play().catch((error) => {
          console.log("User interaction required before playing sound");
        });
        titleRef.current = "::: You have new message";
        setAnimateTabTitle(true);
        setTitleIndex(0);
      }
    }, [gContext.twilioChat.chatUnreaded]);

    useEffect(() => {
      if (typeof document !== "undefined" && typeof window !== 'undefined'){
        if(animateTabTitle && titleIndex !== -1) {
          if(!localStorage.getItem("title")) localStorage.setItem("title", (typeof document !== "undefined" && document.getElementsByTagName('title')[0].textContent) || "");
          const updateTitle = () => {
            const title = titleRef.current;
            if (title) {
              const nextIndex = (titleIndex + 1) % title.length;
              document.title = title.slice(nextIndex) + " " + title.slice(0, nextIndex);
              setTitleIndex(nextIndex);
            }
          };
          intervalRef.current = setInterval(updateTitle, 200); // Change the title every 200ms
          return () => {
            if (intervalRef.current && !gContext.twilioChat.chatUnreaded) {
              document.title = localStorage.getItem("title") || '';
              clearInterval(intervalRef.current); 
              intervalRef.current = null;
              titleRef.current = "";
              setAnimateTabTitle(false);
              setTitleIndex(-1);
              scrollToBottom();
            }
          };
        } else {
          clearInterval(intervalRef.current); 
          intervalRef.current = null;
          titleRef.current = "";
          setAnimateTabTitle(false);
          setTitleIndex(-1);
        }
      }
    }, [titleIndex]);
    
    useEffect(() => {
        if(!countdownInterval && didAgentPost(gContext.twilioChat.messages)){
            setCountdownInterval(setInterval(() => {
            if(gContext.twilioChat.lastTimeActive !== null){
                const elapsedSeconds = timer.current + 1;
                localStorage.setItem("timer", elapsedSeconds.toString());
                if(elapsedSeconds === parseInt(process.env.GATSBY_TWILIO_TIME1 || '300')) {    // After: 300s = 5min
                    gContext.goSetTwilioChat({...gContext.twilioChat, messages: [{
                        senderType: 'AGENT',
                        createdAt: moment(new Date()).format(),
                        from: 'YouFibre',
                        message: "Could you please confirm if you're still waiting on this chat? If so, kindly send a reply. If we don’t hear from you, the chat will automatically close. Thank you.",
                        form: null,
                        files: [],
                        messageId: 321,
                        fromId: `${Date.now()}-0000-0000-0000-000000000000`
                    }, ...gContext.twilioChat.messages]});
                    timer.current = elapsedSeconds;
                } else if(elapsedSeconds > parseInt(process.env.GATSBY_TWILIO_TIME2 || '360')){ // After: 360s = 6min
                    closeConversation("SYSTEM");
                    gContext.goSetTwilioChat({
                      ...gContext.twilioChat, 
                      ...initialTwilioChatState, 
                      openChat: gContext.twilioChat.openChat, 
                      chatClosing: true, 
                      chatClosingType: "SYSTEM",
                      fullscreen: gContext.twilioChat.fullscreen,
                      side: gContext.twilioChat.side
                    });
                    clearInterval(countdownInterval);
                    localStorage.removeItem("timer");
                    setCountdownInterval(null);
                    timer.current = 0;
                } else {
                    timer.current = elapsedSeconds;
                }
            } else {
                clearInterval(countdownInterval);
                localStorage.removeItem("timer");
                setCountdownInterval(null);
                countdownInterval = null;
                timer.current = 0;
            }
            return true;
            }, 1000)); 
        } else {
            if(!didAgentPost(gContext.twilioChat.messages)){
                clearInterval(countdownInterval);
                setCountdownInterval(null);
                countdownInterval = null;
            }
            timer.current = 0;
        }
      }, [gContext.twilioChat.lastTimeActive]);
    
    // Upload files - Configuration
    const fileCount = 5;
    const fileSize = 10; // MB
    const fileExtensions = ['jpg', 'jpeg', 'png', 'pdf', 'gif', 'txt', 'mp4', 'mp3'];

    // Upload files - for input form or drag&drop over chat
    const {getRootProps, getInputProps, open} = useDropzone({
        noClick: true,
        noKeyboard: true,
        onDrop: async (acceptedFiles:any) => {
            gContext.goSetTwilioChat({...gContext.twilioChat, uploadArea: false});
            if(gContext.twilioChat.chatCaseId){
                acceptedFiles = acceptedFiles.filter(item => item.size < (fileSize*1048576) ? item : toastrMsg(`Maximum file size is ${fileSize}MB`, "error"));
                acceptedFiles = acceptedFiles.filter(item => fileExtensions.some(ext => item.name.endsWith(ext)) ? item : toastrMsg(`Unsupported File extension`, "error"));
                acceptedFiles = acceptedFiles.filter(item => Object.keys(gContext.twilioChat.uploadedFiles?.filter(ext => ext.name === item.name)).length === 0);
                if(Object.keys(gContext.twilioChat.uploadedFiles || []).length + Object.keys(acceptedFiles).length < fileCount+1){
                    gContext.goSetTwilioChat({...gContext.twilioChat, uploadedFiles: [...gContext.twilioChat.uploadedFiles || [], ...acceptedFiles], uploadArea: false});
                } else {
                    toastrMsg(`Maximum ${fileCount} files`, "warning");
                    gContext.goSetTwilioChat({...gContext.twilioChat, uploadArea: false});
                }
            }
        },
    });

    return (
        <ChatContent
            className={`${gContext.twilioChat.openChat ? 'show' : ''} ${gContext.twilioChat.fullscreen?'fullscreen':''}`}
            {...getRootProps()}
            onDragOver={() => gContext.twilioChat.chatCaseId && gContext.goSetTwilioChat({...gContext.twilioChat, uploadArea: true})}
            onMouseLeave={() => gContext.goSetTwilioChat({...gContext.twilioChat, uploadArea: false})}
        >
            {gContext.twilioChat.lightboxUrl && <Lightbox
                medium={gContext.twilioChat.lightboxUrl}
                large={gContext.twilioChat.lightboxUrl}
                showRotate={true}
                onClose={() => gContext.goSetTwilioChat({...gContext.twilioChat, lightboxUrl: null})}
            />} 

            <ChatHeader />
            <MessageList />
            <ChatInput open={open} />
            <input {...getInputProps()} />
            {/* Loading cover for chat */}
            { gContext.twilioChat.chatLoading && gContext.twilioChat.openChat && <div className='cover'><img className="make-it-white" src={loadingIcon} width={14} height={14} /></div> }
        
            {/* Confirmation for closing conversation from client's side */}
            { gContext.twilioChat.confirmClosing && gContext.twilioChat.openChat && <div className='cover closed'>
            <h4>Close conversation</h4>
            <span>Are you sure you want to end this conversation?</span>  
            <button onClick={() => gContext.goSetTwilioChat({...gContext.twilioChat, confirmClosing: false})}>Cancel</button>
            <button onClick={() => { closeConversation("CUSTOMER"); }} className='blue-bg'>Confirm</button>
            </div> }

            {/* Closed conversation from agent's or systems side */}
            { gContext.twilioChat.chatClosing && gContext.twilioChat.openChat && <div className='cover closed'>
            <h4>Conversation is closed</h4>
            <span>To keep the conversation going, create a new conversation.</span>  
            <button onClick={() => gContext.goSetTwilioChat({
              ...gContext.twilioChat, 
              ...initialTwilioChatState, 
              openChat: false, 
              fullscreen: gContext.twilioChat.fullscreen,
              side: gContext.twilioChat.side
            })}>Close</button>
            <button onClick={() => gContext.goResetTwilioChat()} className='blue-bg'>Create new</button>
            </div> }

            {/* Upload files */}
            { gContext.twilioChat.uploadArea && gContext.twilioChat.chatCaseId && gContext.twilioChat.openChat && <div className='cover upload'>
                <h4>Drag and drop your files here</h4>
                <p>Maximum {fileCount} files. Limit {fileSize}MB per file.</p>
                <p>Supported files: {fileExtensions && fileExtensions.map((item:string, index) => !['mp4','mp3'].some(ext => ext === item) && (index === 0 ? item : `, ${item}`))}</p>
            </div> }

        </ChatContent>
    );
};

export default Chat;