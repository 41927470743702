import React, { useContext, useState, useEffect, memo, useRef } from "react";
import GlobalContext from "../../../../context/GlobalContext";
import icoYouFibre from '../../../../assets/image/rebrand/svg/favicon.svg';
import linkifyHtml from 'linkify-html';
import { Col, Row } from 'react-bootstrap';
import { DebounceInput } from 'react-debounce-input';
import { BsFiletypePdf } from "react-icons/bs";
import { BsFiletypeTxt } from "react-icons/bs";
import { BsFiletypeMp4 } from "react-icons/bs";
import { BsFiletypeMp3 } from "react-icons/bs";

import moment from 'moment';
import { validateForm, validateFormInput } from "../utils/validate";
import { chatLocalStorage, loadZipCodesAsync } from "../services/chatService";
import EventPost from "./styled/EventPost";
import MyPost from "./styled/MyPost";
import YFPost from "./styled/YFPost";

interface Item {
    createdAt: string;
    from?: string | null;
    files?: string | null;
    message: string | null;
    form: FormType | null;
    messageId: string;
    fromId: string;
    senderType: string;
}

interface FormType {
    type: string;
    value: Array<{ title: string; action: string; placeholder?: string | null; type?: string; required?: boolean; options?:object }>;
}

type Props = {
    item: Item,
    buttonAction: any,
    setChatForm: any,
};

interface Result {
  available: boolean | null;
  salesStatus: boolean | null;
  loading: boolean;
  data: Array<{ label: string; value: string; }> | null;
}

const Message = ({item, buttonAction, setChatForm }:Props) => {
    const gContext: any = useContext(GlobalContext);
    
    const formRef = useRef<any>(null);
    let [formValues, setFormValues] = useState({});
    const [postCode, setPostCode] = useState('');
    const [postCodeAddress, setPostCodeAddress] = useState('');
    const [invalidFields, setInvalidFields] =useState<Set<string>>(new Set());
    const [searchResult, setSearchResult] = useState<Result>({
        available: false,
        salesStatus: null,
        loading: false,
        data: null,
    });
    
    useEffect(() => {      
      setFormValues({});
      setPostCode('');
      formRef.current = null;
      setSearchResult({
        ...searchResult,
        data: null,
        loading: false,
      });
    }, [item]);
    
    useEffect(() => {      
      postCode.length > 0
        ? loadOptionsAsync(postCode)
        : setSearchResult({
          ...searchResult,
          data: null,
        });
    }, [postCode]);

    const loadOptionsAsync = async(input) => {
      setSearchResult({
        ...searchResult,
        data: null,
        loading: true,
      });
      updateFormValues('address', 'text', null);
      try {
        const zipList = await loadZipCodesAsync(input);        
        setSearchResult({
          ...searchResult,
          loading: false,
          data: zipList,
        });
      } catch (error) { console.error('Error fetching ZIP codes:', error); }
    };

    const updateFormValues = (action, type, value) => {
      formValues[action] = value;
      const tmpInvalidFields = invalidFields;
      validateFormInput(type, value)?tmpInvalidFields.delete(action):tmpInvalidFields.add(action); 
      setInvalidFields(tmpInvalidFields);   
      setFormValues({...formValues});
      setChatForm({...formValues});
    };

    const getIcon = (url:string) => {
      if(url && typeof window !== 'undefined'){
        const extension = url.split('.').pop()?.toLocaleLowerCase();
        switch(extension){
          case 'pdf': return <BsFiletypePdf className='file' onClick={() => window.open(url,'_blank')}/>; break;
          case 'txt': return <BsFiletypeTxt className='file' onClick={() => window.open(url,'_blank')}/>; break;
          case 'mp4': return <BsFiletypeMp4 className='file' onClick={() => window.open(url,'_blank')}/>; break;
          case 'mp3': return <BsFiletypeMp3 className='file' onClick={() => window.open(url,'_blank')}/>; break;
          case 'svg': return <InlineSvg className='file' url={url} onClick={() => window.open(url,'_blank')} />; break;
          default: return <img className='file' src={url} onClick={() => gContext.goSetTwilioChat({...gContext.twilioChat, lightboxUrl: url})} />; break;
        }
      }
    };

    const InlineSvg = ({ url, ...props }) => {
      const [svgContent, setSvgContent] = useState<string | null>(null);  
      useEffect(() => {
        const fetchSvg = async () => {
          try {
            const response = await fetch(url);
            const svgText = await response.text();
            setSvgContent(svgText);
          } catch (error) {
            console.error("Error loading SVG:", error);
          }
        };  
        fetchSvg();
      }, [url]);  
      return (
        <div {...props}
          dangerouslySetInnerHTML={{ __html: svgContent || "" }}
          aria-hidden="true"
        />
      );
    };

    const agentMessage = (item) => {
      return <>
        <YFPost key={item.messageId}>
          <img src={icoYouFibre} />
          <Col className='p-0 m-0'>
            <div>
              {item.message && <span dangerouslySetInnerHTML={{ __html: linkifyHtml(item.message, { target: "_blank" }).replaceAll(/\r\n|\n/g, "<br />") }} />}
            </div>
            {Array.isArray(item.files) && item.files.length > 0 &&  <div className='files'>
              {item.files.map((file) => getIcon(file.url))}
            </div>}
            <Row className='justify-between w-100 p-0 m-0 mt-1'>
              <small dangerouslySetInnerHTML={{ __html: item.from }} />
              { item.createdAt && <small className='pt-1'>{moment(item.createdAt).format('HH:mm')}</small> }
            </Row>
          </Col>
        </YFPost>
      </>
    };
  
    const customerMessage = (item) => {
      return <>
        <MyPost key={item.messageId}>
          { !item.form && <>
            {item.message && <span dangerouslySetInnerHTML={{ __html: linkifyHtml(item.message, { target: "_blank" }).replaceAll(/\r\n|\n/g, "<br />") }} />}
            {Array.isArray(item.files) && item.files.length > 0 &&  <div className='files'>
              {item.files.map((file) => getIcon(file.url))}
            </div>}
            <Row className='justify-between w-100 p-0 m-0'>
            {item.from === 'Client' ? <small>Client</small> : <small dangerouslySetInnerHTML={{ __html: item.from }} /> }
            { item.createdAt && <small className='pt-1'>{moment(item.createdAt).format('HH:mm')}</small> }
            </Row></>}
          { item.form?.type === "buttons" &&  <>
            { item.form?.value.map((itm, i) => <button key={i} onClick={() => buttonAction(itm.action)} dangerouslySetInnerHTML={{ __html: itm.title }} />)}
          </>}
          { item.form?.type === "form" &&  <form ref={formRef} method='get' action='javascript:void(0);'>
            <div className='flex flex-column gap-5'>
              { item.form?.value.map((itm, i) => <>                
                { itm?.type !== 'button' ? <><div dangerouslySetInnerHTML={{ __html: itm.title }} className='mt-2' />
                { (itm?.type === 'number' || itm?.type === 'email' || itm?.type === 'text') && 
                <input
                  onChange={(e) => updateFormValues(itm.action, itm?.type, e.target.value)}
                  value={formValues[itm.action]}
                  placeholder={itm.placeholder}
                  maxLength={250}
                  {...(itm?.required && { required: true })}
                  {...(invalidFields.has(itm.action) && { className: 'invalid' })}
                  {...(itm?.type === 'number' && { step: "0.01", min: 0, max: 1000000 })}
                  type={itm?.type}
                  key={i}
                />}
                { itm?.type === 'zip' && <>
                  <DebounceInput
                    required
                    type="text"
                    placeholder={itm.placeholder}
                    debounceTimeout={700}
                    value={postCode}
                    onChange={e => {
                      setPostCode(e.target.value);
                      setPostCodeAddress('');
                      updateFormValues(itm.action, 'text', '/');
                    }}
                  />
                  {postCode && <select
                    required
                    disabled={searchResult.data === null}
                    defaultValue={itm.action}
                    value={postCodeAddress}
                    onChange={(e) => {
                      setPostCodeAddress((e.target as HTMLSelectElement).value);
                      updateFormValues(itm.action, 'select', (e.target as HTMLSelectElement).value);
                    }}
                    className={`mt-1 ${invalidFields.has(itm.action) && 'invalid'}`} key={i}>
                      <option value={''} disabled selected>{searchResult.loading ? "Loading..." : !searchResult.data ? "" : "Select your Address"}</option>
                      {searchResult.data && searchResult.data.map(item => (
                        <option value={item.label}>
                        {item.label as String}
                      </option>
                    ))}
                  </select>}
                  </>
                }
                { itm?.type === 'select' && <select required onChange={(e) => updateFormValues(itm.action, itm?.type, e.target.value)} defaultValue={formValues[itm.action]} value={formValues[itm.action]} key={i}>
                    <option disabled selected hidden></option>
                    {itm?.options && Object.entries(itm?.options).map(([key, title]) => (
                      <option key={key} value={key}>
                        {title as String}
                      </option>
                    ))}
                  </select>
                }
                </> : 
                <button type='submit' {...(invalidFields.size > 0  ? { disabled: true } : {disabled: false})} style={{marginTop: '15px'}}
                  onClick={(e) => {
                    const valid:boolean = validateForm(item.form?.value, formValues) || false;
                    invalidFields.size === 0 && buttonAction(itm.action);
                    const target = e.target as HTMLButtonElement
                    target.disabled = valid;
                  }} >Submit</button>}
                  
              </>)}
            </div>
          </form>}
        </MyPost>
      </>
    };
  
    const eventMessage = (item) => {
      return <>
        <EventPost key={item.messageId}>
          <img src={icoYouFibre} /> {item.message && <span dangerouslySetInnerHTML={{ __html: item.message.replaceAll(/\r\n|\n/g, "<br />") }} />}
        </EventPost>
      </>
    };

    switch(item.senderType){
      case "AGENT": return agentMessage(item);
      case "CUSTOMER": return customerMessage(item);
      case "EVENT": return eventMessage(item);
      default: return customerMessage(item);
    }
};

export default Message;