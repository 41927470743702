export const menuItems = [
  {
    name: "https://www.youfibre.com/why-youfibre",
    label: "Our Broadband",
    isExternal: true,
    items: null
  },
  {
    name: "#",
    label: "About",
    items: [
      {
        name: "https://www.youfibre.com/why-youfibre",
        label: "Why You?",
        isExternal: true,
        items: null
      },
      {
        name: "https://www.youfibre.com/careers",
        label: "Careers",
        isExternal: true,
        items: null
      },
      {
        name: "https://www.youfibre.com/news",
        label: "News",
        isExternal: true,
        items: null
      },
      {
        name: "https://www.youfibre.com/reviews",
        label: "Reviews",
        isExternal: true,
        items: null
      }
    ]
  },
  {
    name: "https://www.youfibre.com/business",
    label: "Business",
    isExternal: true,
    items: null
  },
  /*
  {
    name: "landlord",
    label: "Landlord",
    items: null
  },
  */
  {
    name: "#",
    label: "Support",
    items: [
      {
        name: "knowledge-base",
        label: "Help",
        items: null
      },
      {
        name: "https://www.youfibre.com/voucher",
        label: "Vouchers",
        isExternal: true,
        items: null
      },
      {
        name: "https://www.youfibre.com/socialtariff",
        label: "Social Tariffs",
        isExternal: true,
        items: null
      },
      {
        name: "#chatnow",
        label: "Contact",
        items: null
      }
    ]
  },
  {
    name: "https://www.youfibre.com/refer",
    label: "Refer & Earn",
    isExternal: true,
    items: null
  }
];
