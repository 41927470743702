// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-knowledge-base-article-strapi-faq-articles-article-slug-js": () => import("./../../../src/pages/knowledge-base/article/{StrapiFaqArticles.article_slug}.js" /* webpackChunkName: "component---src-pages-knowledge-base-article-strapi-faq-articles-article-slug-js" */),
  "component---src-pages-knowledge-base-articles-strapi-faq-categories-page-slug-js": () => import("./../../../src/pages/knowledge-base/articles/{strapiFaqCategories.page_slug}.js" /* webpackChunkName: "component---src-pages-knowledge-base-articles-strapi-faq-categories-page-slug-js" */),
  "component---src-pages-knowledge-base-js": () => import("./../../../src/pages/knowledge-base.js" /* webpackChunkName: "component---src-pages-knowledge-base-js" */),
  "component---src-pages-livechat-js": () => import("./../../../src/pages/livechat.js" /* webpackChunkName: "component---src-pages-livechat-js" */)
}

