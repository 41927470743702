import React, { useState, useEffect, useLayoutEffect, useContext, useRef } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import Header from '../Header';
import Footer from '../Footer';
import GlobalContext from '../../../context/GlobalContext';
import imgFavicon from '../../../assets/favicon.png';
import { Link } from 'gatsby';
import 'antd/dist/antd.css';
import './../../../assets/fonts/fonts.css';
import './bootstrap-custom.scss';
import './app.scss';
import TwilioChat from '../TwilioChat';
import TwilioChat2 from '../TwilioChat2';

const axios = require('axios');

const setting = {
  color: {
    theme: '#fff',
  },
};

const Layout = ({ children }) => {
  const gContext = useContext(GlobalContext);
  const [visibleLoader, setVisibleLoader] = useState(true);

  useEffect(async () => {
    fetchFaqArticlesWithRetry();
    fetchFaqCategoriesWithRetry();
  }, []);

  const eleRef = useRef();

  const fetchFaqArticlesWithRetry = async (maxRetries = 10, delay = 500) => {
    let attempts = 0;
  
    const fetchData = async () => {
      try {
        const resp = await axios.get(`https://yfstrapi.youfibre.com/faq-articles`);
        if (resp && resp.data) {
          gContext.setFaqArticles(resp.data);
          return;
        }
      } catch (error) {
        attempts += 1;
        
        if (attempts < maxRetries) {
          await new Promise((resolve) => setTimeout(resolve, delay));
          delay *= 2;
          return fetchData();
        }
      }
    };
  
    await fetchData();
  };

  const fetchFaqCategoriesWithRetry = async (maxRetries = 10, delay = 500) => {
    let attempts = 0;
  
    const fetchData = async () => {
      try {
        const resp = await axios.get(`https://yfstrapi.youfibre.com/faq-categories`);
        if (resp && resp.data) {
          gContext.setFaqCategories(resp.data.sort((a, b) => a.id - b.id));
          return;
        }
      } catch (error) {
        attempts += 1;
        if (attempts < maxRetries) {
          await new Promise((resolve) => setTimeout(resolve, delay));
          delay *= 2;
          return fetchData();
        }
      }
    };
  
    await fetchData();
  };

  return (
    <>
      <Helmet>
        <title>YouFibre Support</title>
        <link rel="icon" type="image/png" href={imgFavicon} />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no, maximum-scale=1.0, user-scalable=0"
        />
        <script
          type="text/javascript"
          src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
          async
        ></script>
      </Helmet>
      <TwilioChat />
      <TwilioChat2 id="main_chat" fullscreen={false} side='right' />
      <div className="site-wrapper overflow-x-hidden" ref={eleRef}>
        {
          gContext.headerVisible &&
          <Header isDark={gContext.headerDark} />
        }
        <div className="page-wrapper">
          {children}
        </div>
        {
          gContext.footerVisible &&
          <Footer isDark={gContext.footerDark} />
        }
      </div>
    </>
  );
};

export default Layout;
