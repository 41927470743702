import React, { useContext } from "react";
import GlobalContext from "../../../../context/GlobalContext";
import { Row } from 'react-bootstrap';
import { navigate } from 'gatsby';
import Container from "./Container";
import icoYouFibre from '../../../../assets/image/rebrand/svg/favicon.svg';
import icoCloseChat from '../../../../assets/image/png/ico-close.png';
import Header from "./styled/Header";
import { initialTwilioChatState } from "../utils/chats";

const ChatHeader = ({...props}) => {
    const gContext: any = useContext(GlobalContext);
    return (
        <Header>
          <Container className='justify-between'>
            <Row className='p-0 m-0 align-items-center'>
              <a href="https://www.youfibre.com/">
                <img src={icoYouFibre} onClick={() => window.location.href = 'https://www.youfibre.com/'}/>
                <span>&nbsp;YouFibre</span>
              </a>
            </Row>            
            <Row className='p-0 m-0 align-items-center'>
              { !gContext.twilioChat.chatConversationId && gContext.twilioChat.chatUserType && <button onClick={() => gContext.goSetTwilioChat({...gContext.twilioChat, ...initialTwilioChatState, fullscreen: gContext.twilioChat.fullscreen, side: gContext.twilioChat.side, openChat: true})} className='small'>Back</button> }
              { gContext.twilioChat.chatConversationId && <button onClick={() => gContext.twilioChat.chatCaseId && gContext.goSetTwilioChat({...gContext.twilioChat, confirmClosing: true, chatClosingType: 'CUSTOMER'})} className='small'>End conversation</button> }
              { !gContext.twilioChat.fullscreen && <img src={icoCloseChat} className='close' onClick={() => gContext.goSetTwilioChat({...gContext.twilioChat, openChat: !gContext.twilioChat.openChat}) } />}
            </Row>          
          </Container>
        </Header>
    );
};

export default ChatHeader;