import React, { useContext } from "react";
import youfibreLogo from "../../assets/image/rebrand/svg/yf-logo.svg";
import GlobalContext from "../../context/GlobalContext";

const Logo = ({ className = "", ...rest }) => {

  const gContext = useContext(GlobalContext)
  return (
    <a href="https://www.youfibre.com/" onClick={() => { gContext.goSetRegistrationData(null) }} className={`${className}`} {...rest} >
      <img src={youfibreLogo} className="logo" alt="YouFibre Logo" />
    </a>
  );
};

export default Logo;
