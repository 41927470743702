import React, { useContext, useState, useEffect } from "react";
import styled from 'styled-components';
import GlobalContext from "../../../../context/GlobalContext";
import icoChat from '../../../../assets/image/rebrand/svg/chat.svg';
import icoCloseChat from '../../../../assets/image/png/ico-close.png';
import WidgetButton from "./styled/WidgetButton";

const Widget = ({...props}) => {
    const gContext: any = useContext(GlobalContext);

    const [openChat, setOpenChat] = useState(false);
    const [fullscreen, setFullscreen] = useState(false);
  
    useEffect(() => {
        setOpenChat(gContext.twilioChat.openChat);
    }, [gContext.twilioChat.openChat]);
  
    useEffect(() => {
        setFullscreen(gContext.twilioChat.fullscreen);
    }, [gContext.twilioChat.fullscreen]);
          
    const chatToggle = () => {
        gContext.twilioChat.chatClosing && gContext.goResetTwilioChat();
        let tmpState = {...gContext.twilioChat, openChat: !gContext.twilioChat.openChat};
        if(!gContext.twilioChat.openChat && gContext.twilioChat.chatUnreaded) tmpState = {...tmpState, chatUnreaded: false};
        gContext.twilioChat.messages && gContext.goSetTwilioChat(tmpState);
    };

    return (
        <WidgetButton onClick={() => chatToggle()} className={`YFChat ${openChat ? 'open' : ''} ${fullscreen?'fullscreen':''}`}>
          <img src={icoChat} className='icon-default' />
          <img src={icoCloseChat} className='icon-hover' />
          {gContext.twilioChat.chatUnreaded && <small className='unread'>1</small>}
        </WidgetButton>
    );
};

export default Widget;